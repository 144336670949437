import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import AdminPage from './pages/AdminPage';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import SignupPage from './pages/SignupPage';
import HospitalPage from './pages/HospitalPage';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/" element={<MainPage />} />
        <Route path="/:hospitalName" element={<HospitalPage />} />
        <Route path="/admin" element={<PrivateRoute> <AdminPage /> </PrivateRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
