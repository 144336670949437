import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomDropdown from '../components/CustomDropdown';
import './AdminPage.css'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';



// Group guidelines by the specified columns
const groupGuidelinesByColumn = (guidelines) => {
  const columnsOrder = ['FEN/GI', 'Respiratory', 'Cardiac', 'Neurologic', 'Infectious Dz', 'Micropreemie', 'Discharge', 'Miscellaneous'];
  const grouped = guidelines.reduce((acc, guideline) => {
    const column = columnsOrder.includes(guideline.column) ? guideline.column : 'Miscellaneous';
    if (!acc[column]) {
      acc[column] = [];
    }
    acc[column].push(guideline);
    return acc;
  }, {});

  // Ensure all columns are present in the correct order, even if empty
  return columnsOrder.reduce((acc, column) => {
    acc[column] = grouped[column] || [];
    return acc;
  }, {});
};


function AdminPage() {
  const [guidelines, setGuidelines] = useState([]);
  const [newGuideline, setNewGuideline] = useState({ guidelineName: '', when: '', date: '', column: 'Miscellaneous' });
  const [successMessage, setSuccessMessage] = useState(false); // State for the success message
  const [failureMessage, setFailureMessage] = useState(false); // State for the error message
  const [message, setMessage] = useState('');
  const [sessionExpired, setSessionExpired] = useState(false);
  const token = localStorage.getItem('token');
  const hospital = localStorage.getItem('hospital'); // Get the hospital name from localStorage

  // Fetch the current guidelines on component mount
  useEffect(() => {
    const fetchGuidelines = async () => {
      try {
        const res = await axios.get(`https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/guideline/${hospital}`);
        console.log("Fetched guidelines:", res.data); // Debug: Check the fetched data structure
        setGuidelines(res.data);
      } catch (err) {
        console.error('Failed to fetch guidelines:', err.response?.data?.message || err.message);
      }
    };
    fetchGuidelines();

    // Timer to track session time
    const sessionTimer = setTimeout(() => {
      setSessionExpired(true);
      setFailureMessage(true);
      setMessage("Session expired. Please sign out and sign back in.");
    }, 3600000); // 1 hour = 3600000 ms

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(sessionTimer);
  }, [hospital]);

  // Group guidelines by the specified columns
  const groupedGuidelines = groupGuidelinesByColumn(guidelines);

  function reorder(list, startIndex, endIndex) {
    // make a shallow copy
    const newList = Array.from(list);
    // remove the item
    const [removed] = newList.splice(startIndex, 1);
    // insert it at the new index
    newList.splice(endIndex, 0, removed);
    return newList;
  }
  
  function move(sourceList, destList, source, destination) {
    const sourceClone = Array.from(sourceList);
    const destClone = Array.from(destList);
    const [removed] = sourceClone.splice(source.index, 1);
    destClone.splice(destination.index, 0, removed);
  
    return {
      source: sourceClone,
      destination: destClone
    };
  }
  

   // -------------- DRAG AND DROP HANDLER --------------
   const onDragEnd = (result) => {
    const { source, destination } = result;
  
    // If dropped outside a droppable or in the same place, do nothing
    if (!destination || 
        (destination.droppableId === source.droppableId &&
         destination.index === source.index)) {
      return;
    }
  
    // Convert your grouped object to a mutable copy
    const newGrouped = { ...groupedGuidelines };
  
    // If we're dragging within the same column
    if (source.droppableId === destination.droppableId) {
      // reorder items in that column
      const columnItems = newGrouped[source.droppableId];
      const reordered = reorder(columnItems, source.index, destination.index);
      newGrouped[source.droppableId] = reordered;
  
    } else {
      // Move item from the start column to the finish column
      const startColumn = newGrouped[source.droppableId];
      const finishColumn = newGrouped[destination.droppableId];
  
      const { source: newStart, destination: newFinish } = move(
        startColumn,
        finishColumn,
        source,
        destination
      );
      newGrouped[source.droppableId] = newStart;
      newGrouped[destination.droppableId] = newFinish;
    }
  
    // Rebuild a single array of guidelines with the correct column assignments
    const updatedGuidelines = [];
    for (let columnName of Object.keys(newGrouped)) {
      newGrouped[columnName].forEach((item) => {
        item.column = columnName;
        updatedGuidelines.push(item);
      });
    }
  
    setGuidelines(updatedGuidelines);
  };
  


function testInputs(guidelines) {

  let result = {
    validInput: true,
    error: ''
  };

  for (let i = 0;  i < guidelines.length; i++) {
    const dateInput = convertWeeksDaysToDays(guidelines[i].date);
    const condition = guidelines[i].when

    //Test the date input
    if (dateInput !== "No Date" && !dateInput.includes("PMA") && !dateInput.includes("DOL")) {
      result.validInput = false;
      result.error = `Invalid input for the date to be calculated on guideline "${guidelines[i].guidelineName}"`;
    } else if (dateInput !== "No Date" && !containsNumber(dateInput)){
      result.validInput = false;
      result.error = `Add a number(s) for the date to be calculated on guideline "${guidelines[i].guidelineName}" (Check if you replaced your '_' with numbers)`;
    }
    
    if (dateInput.includes('PMA')){
      if (!dateInput.includes('w')  && !dateInput.includes('d')){
        result.validInput = false;
        result.error = `Invalid input for the date to be calculated on guideline "${guidelines[i].guidelineName}" Include a weeks OR day indicator for PMA's (ex: 20w 5d, 20w, 5d)`;
    }
    
  }

    //Test the when input
    if (!translateCondition(condition)){
      result.validInput = false;
      result.error = `Invalid input for the condition on guideline "${guidelines[i].guidelineName}"`;
    }
  }
  return result;

  //Attempts to parse condition, returns true or false
  function translateCondition(condition) {
    try {
      
        // Remove all '/' characters
        condition = condition.replace(/\//g, '');
    
        // Remove all whitespace
        condition = condition.replace(/\s+/g, '');
        condition = condition.toLowerCase()
            if (condition === "allpatients"){
                return true
            }
    
        // Handle range expressions like "XXXXg TO YYYYg"
        condition = condition.replace(/(\d+)gTO(\d+)g/gi, (_, minWeight, maxWeight) => {
            return `(birthWeight >= ${minWeight} && birthWeight <= ${maxWeight})`;
        });

        // Handle gestational age range expressions like "22w0d TO 24w6d"
        condition = condition.replace(/(\d+)w(\d+)dTO(\d+)w(\d+)d/gi, (_, minWeeks, minDays, maxWeeks, maxDays) => {
            const minGestAgeDays = parseInt(minWeeks) * 7 + parseInt(minDays);
            const maxGestAgeDays = parseInt(maxWeeks) * 7 + parseInt(maxDays);
            return `(gestAgeDays >= ${minGestAgeDays} && gestAgeDays <= ${maxGestAgeDays})`;
        });
    
        // Handle "Xg" conditions with <=, >=, <, >
        condition = condition.replace(/<=(\d+)g/gi, (_, weight) => {
            return `birthWeight <= ${weight}`;
        }).replace(/>=(\d+)g/gi, (_, weight) => {
            return `birthWeight >= ${weight}`;
        }).replace(/<(\d+)g/gi, (_, weight) => {
            return `birthWeight < ${weight}`;
        }).replace(/>(\d+)g/gi, (_, weight) => {
            return `birthWeight > ${weight}`;
        });
    
        // Handle gestational age conditions with <=, >=, <, >
        condition = condition.replace(/<=(\d+)w(\d+)d/gi, (_, weeks, days) => {
            return `gestAgeDays <= (${weeks} * 7 + ${days})`;
        }).replace(/>=(\d+)w(\d+)d/gi, (_, weeks, days) => {
            return `gestAgeDays >= (${weeks} * 7 + ${days})`;
        }).replace(/<(\d+)w(\d+)d/gi, (_, weeks, days) => {
            return `gestAgeDays < (${weeks} * 7 + ${days})`;
        }).replace(/>(\d+)w(\d+)d/gi, (_, weeks, days) => {
            return `gestAgeDays > (${weeks} * 7 + ${days})`;
        }).replace(/<=(\d+)w/gi, (_, weeks) => {
            return `gestAgeDays <= (${weeks} * 7)`;
        }).replace(/>=(\d+)w/gi, (_, weeks) => {
            return `gestAgeDays >= (${weeks} * 7)`;
        }).replace(/<(\d+)w/gi, (_, weeks) => {
            return `gestAgeDays < (${weeks} * 7)`;
        }).replace(/>(\d+)w/gi, (_, weeks) => {
            return `gestAgeDays > (${weeks} * 7)`;
        });
    
        // Replace 'AND' with '&&' and 'OR' with '||'
        condition = condition.replace(/AND/gi, '&&').replace(/OR/gi, '||');
    
        // Return True if condition can be parsed
        const variables = { birthWeight: 5, gestAgeDays: 5 };
        if (evaluateExpression(condition, variables) === "true" || evaluateExpression(condition, variables) === "false"){
          return true;
        }
        return false;
    } catch (error) {
        console.log('input Invalid for condition')
        return false;
    }
  }

  function evaluateExpression(expr, variables = {}) {
    // Regular expressions for different operators and parentheses
    let parens = /\(([^()]+)\)/;            // Regex for identifying parenthetical expressions
    let exp = /(\d+(?:\.\d+)?) ?\^ ?(\d+(?:\.\d+)?)/; // Regex for exponentials
    let mul = /(\d+(?:\.\d+)?) ?\* ?(\d+(?:\.\d+)?)/; // Regex for multiplication
    let div = /(\d+(?:\.\d+)?) ?\/ ?(\d+(?:\.\d+)?)/; // Regex for division
    let add = /(\d+(?:\.\d+)?) ?\+ ?(\d+(?:\.\d+)?)/; // Regex for addition
    let sub = /(\d+(?:\.\d+)?) ?- ?(\d+(?:\.\d+)?)/;  // Regex for subtraction
    let comp = /(\d+(?:\.\d+)?) ?(>=|<=|>|<|==|!=) ?(\d+(?:\.\d+)?)/; // Comparison operators
    let logic = /(\btrue\b|\bfalse\b) ?(&&|\|\|) ?(\btrue\b|\bfalse\b)/; // Logical operators

    // Replace variable names with their values in the expression
    expr = expr.replace(/\b\w+\b/g, function(match) {
        return variables.hasOwnProperty(match) ? variables[match] : match;
    });

    // Recursive function to evaluate the expression
    function evaluate(expr) {
        if (isNaN(Number(expr))) {
            if (parens.test(expr)) {
                let newExpr = expr.replace(parens, function(match, subExpr) {
                    return evaluate(subExpr);  // Recursively evaluate parentheses
                });
                return evaluate(newExpr);
            } else if (exp.test(expr)) {
                let newExpr = expr.replace(exp, function(match, base, pow) {
                    return Math.pow(Number(base), Number(pow));  // Evaluate exponentiation
                });
                return evaluate(newExpr);
            } else if (mul.test(expr)) {
                let newExpr = expr.replace(mul, function(match, a, b) {
                    return Number(a) * Number(b);  // Evaluate multiplication
                });
                return evaluate(newExpr);
            } else if (div.test(expr)) {
                let newExpr = expr.replace(div, function(match, a, b) {
                    if (b !== 0) return Number(a) / Number(b);  // Evaluate division
                    else throw new Error('Division by zero');
                });
                return evaluate(newExpr);
            } else if (add.test(expr)) {
                let newExpr = expr.replace(add, function(match, a, b) {
                    return Number(a) + Number(b);  // Evaluate addition
                });
                return evaluate(newExpr);
            } else if (sub.test(expr)) {
                let newExpr = expr.replace(sub, function(match, a, b) {
                    return Number(a) - Number(b);  // Evaluate subtraction
                });
                return evaluate(newExpr);
            } else if (comp.test(expr)) {
                let newExpr = expr.replace(comp, function(match, left, operator, right) {
                    left = Number(left);
                    right = Number(right);
                    switch (operator) {
                        case '>=': return left >= right;
                        case '<=': return left <= right;
                        case '>': return left > right;
                        case '<': return left < right;
                        case '==': return left === right;
                        case '!=': return left !== right;
                        default: throw new Error(`Unexpected operator: ${operator}`);  // Default case to handle unexpected operators
                    }
                });
                return evaluate(newExpr);
            } else if (logic.test(expr)) {
                let newExpr = expr.replace(logic, function(match, left, operator, right) {
                    left = (left === 'true');
                    right = (right === 'true');
                    return operator === '&&' ? left && right : left || right;  // Evaluate logical operators
                });
                return evaluate(newExpr);
            } else {
                return expr;  // Return final evaluated expression
            }
        }
        return Number(expr);  // Return if the expression is a number
    }

    // Start evaluating the full expression
    return evaluate(expr);
  }


  function convertWeeksDaysToDays(input) {
    return input.replace(/(\d+)w\s*(\d*)d?/g, (match, weeks, days) => {
        let totalDays = parseInt(weeks) * 7 + (days ? parseInt(days) : 0);
        return totalDays + "d";
    }).replace(/(\d+)w/g, (match, weeks) => {
        return parseInt(weeks) * 7 + "d";
    });
  }

  function containsNumber(str) {
    return /\d/.test(str);
  }
}

  // Update the guidelines (including adding a new guideline)
  const handleUpdateGuidelines = async () => {
    if (sessionExpired) {
      setSuccessMessage(false);
      setFailureMessage(true);
      setMessage("Session expired. Please sign out and sign back in.");
      return;
    }
    try {
        // Check if the new guideline form is empty
        if (newGuideline.guidelineName || newGuideline.when || newGuideline.date || newGuideline.column) {
            // If any field in the new guideline is filled, add it to the guidelines array
            let updatedGuidelines = [...guidelines]
            for  (let i = 0; i < updatedGuidelines.length; i++) {
              if (updatedGuidelines[i].guidelineName === '' ||  updatedGuidelines[i].when === "" || updatedGuidelines[i].date === "") {
                setSuccessMessage(false)
                setFailureMessage(true)
                setMessage(`One of your fields are empty for guideline "${updatedGuidelines[i].guidelineName}"`)
                return
              }
            }
            if (newGuideline.guidelineName && newGuideline.when && newGuideline.date && newGuideline.column) {
                updatedGuidelines = [...guidelines, newGuideline];
            } else if (newGuideline.guidelineName || newGuideline.when || newGuideline.date){
              setSuccessMessage(false)
              setFailureMessage(true)
              setMessage(`The guideline you want to add is not complete`)
              return
            }

            const isInputValid = testInputs(updatedGuidelines).validInput
            const errorMessage =  testInputs(updatedGuidelines).error
            if (isInputValid === false){
              setSuccessMessage(false)
              setFailureMessage(true)
              setMessage(errorMessage)
              return
            }
                setGuidelines(updatedGuidelines);

                // Make the PUT request with the updated guidelines array
                const res = await axios.put('https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/guideline', 
                    { guidelines: updatedGuidelines },
                    { headers: { 'x-auth-token': token } }
                );

                setGuidelines(res.data.guidelines); // Update state with the response data
                setNewGuideline({ guidelineName: '', when: '', date: '', column: 'Miscellaneous' }); // Clear the new guideline form
                console.log('Guidelines updated successfully:', res.data.guidelines);
        } else {
            // If no new guideline is being added, just update the existing guidelines
            const res = await axios.put('https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/guideline', 
                { guidelines },
                { headers: { 'x-auth-token': token } }
            );

            setGuidelines(res.data.guidelines); // Update state with the response data
            console.log('Existing guidelines updated successfully:', res.data.guidelines);
        }
        // Show success message
        setFailureMessage(false)
        setSuccessMessage(true)
        setMessage('Changes saved successfully!');
      
      // Hide the message after 3 seconds
      setTimeout(() => setSuccessMessage(false), 3000);
    } catch (err) {
        setSuccessMessage(false)
        setFailureMessage(true)
        setMessage(`Failed to update guidelines: ${err.response?.data?.message || err.message}`)
        console.error('Failed to update guidelines:', err.response?.data?.message || err.message);
    }
};

  // Handle changes in the existing guidelines form
  const handleChange = (e, id) => {
    const { name, value } = e.target;
    console.log("Changing guideline with id:", id); // Debug: Check the id of the guideline being changed
    setGuidelines(prevGuidelines => 
        prevGuidelines.map(guideline => 
            guideline._id === id ? { ...guideline, [name]: value } : guideline
        )
    );
  };

  // Handle changes in the new guideline form
  const handleNewGuidelineChange = (e) => {
    const { name, value } = e.target;
    setNewGuideline({ ...newGuideline, [name]: value });
  };

  // Delete a guideline
  const handleDeleteGuideline = (id) => {
    console.log("Deleting guideline with id:", id); // Debug: Check the id of the guideline being deleted
    setGuidelines(prevGuidelines => prevGuidelines.filter(guideline => guideline._id !== id));
  };

  return (
    <div>
      <div className="session-reminder">
        <p>For security purposes, you won't be able to make changes after one hour of signing in. Please click the "Update Guidelines" button regularly to save your work.</p>
      </div>
    <div className="admin-page-container">
      <h1>Admin Page - {hospital} Guidelines</h1>
      <h2>Current Guidelines</h2>
      <DragDropContext onDragEnd={onDragEnd}>
          {Object.keys(groupedGuidelines).length > 0 ? (
            Object.keys(groupedGuidelines).map((column, colIndex) => (
              // Each column is a Droppable area
              <Droppable droppableId={column} key={colIndex}>
                {(provided, snapshot) => (
                  <div
                    className="guideline-column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <h3>{column}</h3>
                    {groupedGuidelines[column].map((guideline, index) => (
                      // Each guideline is a Draggable item
                      <Draggable
  draggableId={guideline._id}
  index={index}
  key={guideline._id}
>
  {(provided, snapshot) => (
    <div
      className="guideline-input-container"
      ref={provided.innerRef}
      {...provided.draggableProps /* remove dragHandleProps here */}
    >
      {/* Drag handle indicator */}
      <div
        className="drag-handle"
        {...provided.dragHandleProps} // put dragHandleProps here instead
      >
        <i className="fa-solid fa-grip-lines-vertical"></i>
      </div>

      {/* The rest of your inputs */}
      <input
        className="input-name"
        type="text"
        name="guidelineName"
        value={guideline.guidelineName}
        onChange={(e) => handleChange(e, guideline._id)}
        placeholder="Guideline Name"
      />
      <input
        className="input-regular"
        type="text"
        name="when"
        value={guideline.when}
        onChange={(e) => handleChange(e, guideline._id)}
        placeholder="When"
      />
      <input
        className="input-date"
        type="text"
        name="date"
        value={guideline.date}
        onChange={(e) => handleChange(e, guideline._id)}
        placeholder="Date"
      />
      <select
        className="input-column"
        name="column"
        value={guideline.column}
        onChange={(e) => handleChange(e, guideline._id)}
      >
        <option value="FEN/GI">FEN/GI</option>
        <option value="Respiratory">Respiratory</option>
        <option value="Cardiac">Cardiac</option>
        <option value="Neurologic">Neurologic</option>
        <option value="Infectious Dz">Infectious Dz</option>
        <option value="Micropreemie">Micropreemie</option>
        <option value="Discharge">Discharge</option>
        <option value="Miscellaneous">Miscellaneous</option>
      </select>
      <button
        className="delete-button"
        onClick={() => handleDeleteGuideline(guideline._id)}
      >
        <i className="fas fa-trash-alt"></i>
      </button>
    </div>
  )}
</Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))
          ) : (
            <p>No guidelines found.</p>
          )}
        </DragDropContext>
</div>
<div className="fixed-add-guideline">
  <h3>Add New Guideline</h3>
  <div className="input">
    <input
      className="input-name"
      type="text"
      name="guidelineName"
      value={newGuideline.guidelineName}
      onChange={handleNewGuidelineChange}
      placeholder="Guideline name"
    />
    <input
      className="input-regular"
      type="text"
      name="when"
      value={newGuideline.when}
      onChange={handleNewGuidelineChange}
      placeholder="When guideline will show"
    />
    <CustomDropdown
      className="input-customdropdown"
      name="date"
      value={newGuideline.date}
      onChange={handleNewGuidelineChange}
    />
    <select
      className="input-column"
      name="column"
      value={newGuideline.column}
      onChange={handleNewGuidelineChange}
    >
      <option value="FEN/GI">FEN/GI</option>
      <option value="Respiratory">Respiratory</option>
      <option value="Cardiac">Cardiac</option>
      <option value="Neurologic">Neurologic</option>
      <option value="Infectious Dz">Infectious Dz</option>
      <option value="Micropreemie">Micropreemie</option>
      <option value="Discharge">Discharge</option>
      <option value="Miscellaneous">Miscellaneous</option>
    </select>
  </div>
  <div className="button-message-container">
  <button className="update-button" onClick={handleUpdateGuidelines}>Update Guidelines</button>
   {/* Success Message */}
  {message && successMessage &&(
    <div className="success-message">
      {message}
    </div>
  )}
  {message && failureMessage &&(
      <div className='failure-message'>
      {message}
      </div>
    )}
  </div>
  </div>
</div>
  );
}

// Export the grouped function as a helper or utility if needed elsewhere
export default AdminPage;